

body {
  
    width: 100%;
    height: 100vh;
  
  }
  main {

  min-height: 100vh;  
  }


  .sectionmin { 
        min-height:90vh;  
  }
  .sectionmin2 { 
    min-height:60vh;  
}
 
  #banner {
    min-height: 93vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: static;
    display: flex;
    align-items: center;
    color: rgb(102, 102, 102);
  }
 
  img {
    max-width: 100%;
    height: auto;
  } 
 

  .glyph {
    display: inline-block;
    
    margin: 1em;
    margin-bottom: 1em;
    text-align: center;
    vertical-align: top;
    background: inherit;
  }
  .icontext {
    font-size: 1.8rem;
  }
  .glyph .glyph-icon {
    
    display: block;
    font-family: "Flaticon";
    font-size: 64px;
    line-height: 1;
  }
  .glyph .glyph-icon:before {
    font-size: 64px;
    color: #222;
    margin-left:0px;
  }


  .glyph2 .glyph-icon2 {
    
    display: block;
    font-family: "Flaticon";
    font-size: 120px;
    line-height: 1;
  }
  .glyph2 .glyph-icon2:before {
    font-size: 120px;
    color: rgb(255, 255, 255);
    margin-left:0px;
   
  }



  .iconshome .iconshome:before{
    font-size: 120px;
    color: rgb(255, 255, 255);
    margin-left:0px;
    

  }
  .groupimg    {
    width: auto;
    height: auto;
    border-color: rgb(255, 255, 255);
    border-width: 5px;
    border-style: solid;
  }

  .groupimg2    {
    border-color: rgb(31, 31, 31);
    border-width: 5px;
    border-style: solid;
   background-color: rgb(236, 236, 236);;
  }


  .groupimg3    {
    border-color: rgb(255, 255, 255);
    border-width: 5px;
    border-style: solid;
    
  }
   
  

  @media (max-width: 575.98px) {
    span {
      width: 50%;
    }
    .groupimg    {
      width: 50%;     
    }
    .covidalerts,.navbar-toggler{  
      font-size: 0.7rem!important;
      }
      .lettercardbut{  
        font-size: 0.9rem!important;
        }
  
  }
  
  .carousel {
    width:70%;
    height:auto;
    margin: auto;
  }
  
  

  @media screen and (max-width: 900px){

    .carousel {
      width: auto;
      height: auto;
    }

    .blackas{
      font-size: 1.8rem!important;   
     
      
    }
    .glyph {
    
      width: 5em;


    }
  .icontext{
    font-size: medium;
  }
  
  }
  .servicetitle{
    color: #369E9A;
}
 
  
  .blackbg{
    background-color: rgba(129, 216, 207, 0.842);
   
  }
  .tiffanybg{
     /*background-color: rgb(129, 216, 207);*/
     background-color:#90D1CF;
  }

  /**/
  .tiffanybg2{
    background-color: rgb(129, 216, 207);
   
  }
  
  .bluebg{
    background-color: #369E9A
   
  }
  .whitebg{
    background-color: rgb(211, 200, 212);
   border-color:rgb(211, 200, 212);
   
  }
   .blackas{
    font-size: 2rem; 
    color: rgba(0, 0, 0, 0.726);
    position: absolute;
    left: -45px;  
  }