
.carousel-control-next-icon,
.carousel-control-prev-icon,.carousel-indicators {
  filter: invert(1);
}


.carousel-caption{
  top: -10px;
}

.carousel {
 width:auto!important;

}
.bluetif{
  color: #369E9A;
}
.carousel-indicators {
  display: none !important;
}


.zoom {
   transform: scale(0.9);
   transform-origin:bottom;
  /*background-size: 10px;/* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}