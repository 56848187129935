
 
    .reviews {
      column-count: 3!important;
    }
  
 
 
  @media screen and (max-width: 1200px){
    .reviews {
      column-count: 1!important;
  }
}