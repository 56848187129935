/* Footer */
footer {
  font-weight: 300;
  display: flex;
  justify-content: space-evenly;

  background: #369E9A;
  border-top: 0.5rem solid #3b3b3b;
  color:#fff;
   
}

.text-darks{
  color:#1E2A3A!important;
}


footer > p {
  margin: 0;

}
.footer-link{
  /*  font-family: 'Montserrat', sans-serif;*/
  font-family: 'Josefin Sans', sans-serif; 
}

footer i {
  vertical-align: middle;
}


@media (max-width: 28.75em) {
  footer {
    flex-direction: column;
    text-align: center;
  }
}
