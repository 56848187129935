

@media (max-width: 900px) {
    .display-4{
        font-size: 2rem!important;
    }


  }

