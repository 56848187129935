@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Nunito:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&family=Ubuntu:wght@700&display=swap');


* {
color: rgb(102, 102, 102);
  margin: 0;
  font-family: 'Josefin Sans', sans-serif; 
  /*font-family: 'Nunito', sans-serif;*/
}

.lead , .lead-5{
 /*  font-family: 'Montserrat', sans-serif;*/
  font-family: 'Josefin Sans', sans-serif; 
}
.maintittle{
  font-family: 'Josefin Sans', sans-serif; 
 /* font-family: 'Ubuntu ', sans-serif; */
 font-weight: 700;
}

.hand{
  font-family: 'Dancing Script'!important;
}




html{
  margin: 0;
}

body { 
 
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
