	/*
  	Flaticon icon font: Flaticon
  	Creation date: 04/07/2018 08:55
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-001-x-ray:before { content: "\f100"; }
.flaticon-002-tooth-7:before { content: "\f101"; }
.flaticon-003-toothache:before { content: "\f102"; }
.flaticon-004-dental-5:before { content: "\f103"; }
.flaticon-005-tooth-6:before { content: "\f104"; }
.flaticon-006-tooth-5:before { content: "\f105"; }
.flaticon-007-tooth-cleaning:before { content: "\f106"; }
.flaticon-008-white-teeth:before { content: "\f107"; }
.flaticon-009-teeth:before { content: "\f108"; }
.flaticon-010-smile:before { content: "\f109"; }
.flaticon-011-dental-care-3:before { content: "\f10a"; }
.flaticon-012-tooth-4:before { content: "\f10b"; }
.flaticon-013-tooth-3:before { content: "\f10c"; }
.flaticon-014-dental-care-2:before { content: "\f10d"; }
.flaticon-015-denture:before { content: "\f10e"; }
.flaticon-016-dentist-3:before { content: "\f10f"; }
.flaticon-017-dentist-2:before { content: "\f110"; }
.flaticon-018-dental-4:before { content: "\f111"; }
.flaticon-019-gum:before { content: "\f112"; }
.flaticon-020-dental-3:before { content: "\f113"; }
.flaticon-021-tooth-2:before { content: "\f114"; }
.flaticon-022-dental-care-1:before { content: "\f115"; }
.flaticon-023-dentist-1:before { content: "\f116"; }
.flaticon-024-dental-implant:before { content: "\f117"; }
.flaticon-025-dental-2:before { content: "\f118"; }
.flaticon-026-dental-1:before { content: "\f119"; }
.flaticon-027-dental:before { content: "\f11a"; }
.flaticon-028-dentist:before { content: "\f11b"; }
.flaticon-029-dental-care:before { content: "\f11c"; }
.flaticon-030-tooth-1:before { content: "\f11d"; }
.flaticon-031-broken-tooth:before { content: "\f11e"; }
.flaticon-032-tooth:before { content: "\f11f"; }
.flaticon-033-breath:before { content: "\f120"; }
.flaticon-034-bacteria:before { content: "\f121"; }
.flaticon-035-baby:before { content: "\f122"; }
.flaticon-036-anesthesia:before { content: "\f123"; }