* {

  margin: 0;

}

html{
  margin: 0;
}

.noclick{
  pointer-events: none;
}
.div-block-9 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -23px;
  z-index: 1;
  text-align: center;
}
#team{

  background-color: #81d8d0;
  /*background: linear-gradient(135deg, #81d8d0 0%, #81d8d0 100%);
   d3d5d4
  
  */
}


#services,#tabeo{
background-color: #ECFAF4;
  /*background-color: #ecf0ee;
  /*background: linear-gradient(135deg, #81d8d0 0%, #81d8d0 100%);
   d3d5d4
  */
}

.icontext{
  font-size: 1.3rem;
}

.textContrast{
  color:#4D3E3E;
}


.iconsroundedcards{
  /*background-color: rgb(129, 216, 207);*/
  background-color:#90D1CF;
  border-radius: 80px;
  max-height: 160px!important;
  min-height: 160px!important;
  max-width: 160px!important;
  min-width: 160px!important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.letter {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 760px;
  padding: 60px!important;
  padding-top: 60px;
  background-image: url('../img/textures/letter\ texture.jpg');
  background-position: 0 0;
  background-size: cover;
  box-shadow: 0 5px 8px 0 rgba(0,0,0,.07);
  font-size: 18px;
  line-height: 24px;

}
.div-block-8 {
  margin-top: 40px;
  text-align: right;
}

.image-3 {
  position: absolute;
  top: -84px;
  right: 60px;
  width: 170px;
  border: 4px solid #fff;
  border-radius: 50%;
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before, [class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  margin-left: 0px!important;
}

.iconshome{

  color: rgb(255, 255, 255);
}
.imagecard2 {  
  font-size: 6rem!important;
  background-color: #369E9A;
  display: inline-block;
  border-radius: 60px;
  padding: 0em 0.6em;
}

@media screen and (max-width: 767px){
  .image-3 {
    top: -66px;
    right: -10px;
    width: 120px;
}
.display-1{
  font-size: 3.8rem!important;
}
.fees{
  font-size: 110%;
}
.fees2{
  font-size: 90%!important;
}
.letter {
  padding: 30px!important;
  padding-top: 60px;
}

}


button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
  button:focus {
    outline: none;
  }
  .btn.btn--accent2 {
    color:#4D3E3E;
    border-color: rgb(129, 216, 207);;
    background-color: rgb(129, 216, 207);;
  }
  .btn.btn--accent2:hover {
    background-color: #4D3E3E;
    border-color: #369E9A;
    color: #369E9A;
  }
  .btn.btn--accent2:active {
    border-color: #4D3E3E;
    background: #369E9A;
    color: #4D3E3E ;
  }
  .btn.btn--accent2.btn--filled {
    color:  #4D3E3E;
    border: #4D3E3E;
    background: #369E9A;
  }
  .btn.btn--accent2.btn--filled:hover {
    color:  #4D3E3E;
    background: #4D3E3E;
  }
  .btn.btn--accent2.btn--filled:active {
    border-color: #369E9A;
    background: #369E9A;
  }


.iconcard{
  min-width: 360px;
} 


.btn.btn--block {
  display: block;
  width: 100%;
}
.btn.btn--large {
  font-size: 1.2rem;
}
.btn.btn--x-large {
  font-size: 1.4rem;
}
.btn.btn--small {
  font-size: 0.8rem;
}

 
  .btn.btn--icon-left span {
    padding-right: 5px;
    margin-right: 5px;
    position: relative;
  }
  .btn.btn--icon-left span:after {
    content: "";
    position: absolute;
    right: 0;
    top: 25%;
    bottom: 25%;
    border-right: 1px solid;
    opacity: 0.3;
  }
  
  .btn.btn--icon-right span:after {
    content: "";
    position: absolute;
    left: 0;
    top: 25%;
    bottom: 25%;
    border-left: 1px solid;
    opacity: 0.3;
  }
  .btn.btn--rounded {
    border-radius: 20px;
  }
  #hero  > div > div ,#hero2  > div > div, #Facilities > div > div {
    background-size:cover ;

    min-height: 100vh;

  }

  .herofull {
   
    min-height: 100vh;
  }