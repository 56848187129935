

.headerfix{
  
  max-width:100vw; 
 /*  background-color: transparent;*/
  /*  background-color: linear-gradient(180deg, #ffffff 95%, #90D1CF 5%);*/

}
.headerbg{


  
 /*  background-color: transparent;*/
  /*  background-color: linear-gradient(180deg, #ffffff 95%, #90D1CF 5%);*/
 background-image: linear-gradient(180deg, #ffffff 95%, #90D1CF 5%); 

}

.navelement {
  display: inline-block;
}

.navelement::after {
  content: '';
  width: 0px;
  height: 1px;
  display: block;
  background:  #90D1CF;
  transition: 300ms;
}

.navelement:hover::after {
  width: 100%;
}

.navelement.active {
color:  inherit!important;
background-color:  inherit!important;

}





.headertext{  
  font-family: 'Josefin Sans', sans-serif; 
  /*font-family: 'Nunito', sans-serif;*/
}
.navbar-brand{  
font-size: 1.0rem!important;
}
#collasible-nav-dropdown{
  font-family: 'Josefin Sans', sans-serif; 
  /*font-family: 'Nunito', sans-serif;*/
}
.popover{
  min-width: 80%; /* Max Width of the popover (depending on the container!) */
  max-height: 50vh; 
  overflow-y: auto;  
}
.img-fluid2 { 
  max-width:25%;
  height: auto;
}
.dropdown-item::selection {

  background-color: #90D1CF!important;
}

@media screen and (max-width: 1490px){
  .nav-link{ 
    font-size: 0.9rem!important;
    }


}

@media screen and (max-width: 1330px){
  .nav-link{ 
    font-size: 0.7rem!important;
    }


}

@media screen and (max-width: 1190px){
  .navbar-brand{  
    font-size: 0.7rem!important;
    }

   

}


